<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex>
        <v-card elevation="4" width="800" class="mx-auto">
          <v-card-title primary-title>
            <h3 class="headline mb-0">{{ questionnaire.title }}</h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout wrap>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>نوع الجهة الخارجية</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>
                  {{ getExternalType(replay.externalNavigation.cementPackage) }}
                </strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>اسم العميل</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ replay.externalNavigation.name }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>الشخص المسؤول</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ replay.responsiblePerson }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>رقم الهاتف</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ replay.externalNavigation.mobile }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>الإيميل</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ replay.externalNavigation.email }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>نوع النشاط</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ replay.externalNavigation.activityType }}</strong>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <b>الموقع</b>
              </v-flex>
              <v-flex class="mb-3" xs6 sm6 md6>
                <strong>{{ replay.externalNavigation.location }}</strong>
              </v-flex>
              <v-divider></v-divider>
            </v-layout>

            <v-divider></v-divider>
            <v-layout
              row-wrap
              v-for="question in questions"
              :key="question.question.idQuestions"
            >
              <v-flex
                xs12
                md12
                class="mt-3"
                v-if="question.question.typeId == 1"
              >
                <label
                  ><b>{{ question.question.questionText }}</b></label
                >
                <v-radio-group readonly v-model="question.answerText" row>
                  <v-radio
                    v-for="answer in question.question.ansques"
                    :key="answer.idansques"
                    :label="answer.ans.answerText"
                    :value="answer.ans.answerText"
                  ></v-radio>
                </v-radio-group>
                <v-divider />
              </v-flex>
              <v-flex xs12 md12 class="mt-3" v-else>
                <label
                  ><b>{{ question.question.questionText }}</b></label
                >
                <p>
                  {{
                    question.answerText == "" ? "لا يوجد" : question.answerText
                  }}
                </p>
                <v-divider />
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="$router.go(-1)" style="background:gray;" dark>
              <b>رجوع</b>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "ShowQuestionnaire",
  data() {
    return {
      questionnaire: "",
      replay: "",
      questions: [],
      answerText: ""
    };
  },
  methods: {
    customFilter(item, queryText) {
      const textOne = item.item.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    getAnswer(question, allAnswers) {
      allAnswers.forEach(elm => {
        if (elm.questionId == question.idQuestions)
          this.answerText = elm.answerNavigation
            ? elm.answerNavigation.answerText
            : elm.answerTxt;
      });
    },
    async getQuestionnaire() {
      await this.ApiService.get(
        "Questionnaire/GetReplyByQuestionnaire?id=" +
          this.$route.params.questionnaireId
      )
        .then(res => {
          this.questionnaire = res.data.responseData.questionnaire;
          let allQuestions = this.questionnaire.questions;

          this.questionnaire.replies.forEach(el => {
            if (el != null && el.idreply == this.$route.params.replyId)
              this.replay = el;
          });
          allQuestions.forEach(el => {
            this.getAnswer(el, this.replay.answers);
            this.questions.push({ question: el, answerText: this.answerText });
          });
        })
        .catch(() => {});
    },
    reset() {
      this.receivers = "";
      this.subject = "";
      this.body = "";
      this.files = [];
    },
    getExternalType(id) {
      if (id == 1) return "مصنع";
      else if (id == 2) return "مشروع";
      else if (id == 3) return "مقاول";
      else if (id == 4) return "مبسط";
      else if (id == 5) return "موقف";
      else return "ناقل";
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.getQuestionnaire();
  }
};
</script>
<style scoped>
p,
label,
b,
strong {
  font-size: 15px;
}
</style>
